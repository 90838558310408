import React, { useEffect, useState } from 'react';
import CarItem from '../components/UI/CarItem';
import { Container, Row, Col } from "reactstrap";
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; 


const CarList = () => {
    const [cars, setCars] = useState([]);
    const { slug } = useParams();
    const navigate = useNavigate();
    const filteredCars = cars.filter((item) => item.category_id == slug);

    useEffect(() => {
        console.log("slug is a "+ slug);
        let isMounted = true;

        axios.get('https://admin.rentacarsrilankahbs.com/api/product/allProduts')
            .then(res => {
                if (isMounted) {
                    setCars(res.data);
                    console.log(res.data);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the cars!", error);
            });

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div>
            <Row>

                {slug ? filteredCars ? (
                    filteredCars.map((item) => (
                            <CarItem item={item} />
                    ))
                ) : (
                    <Col>
                        <p>No cars found for this category.</p>
                    </Col>
                ) : 
                cars.map((item) => (
                            <CarItem item={item} />
                    ))
                    
                    }
            </Row>

        </div>
    );
};

export default CarList;
