import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../styles/header.css";
import Logo from "../../assets/all-images/logo/logo.png";
import GoogleTranslate from "../Layout/GoogleTranslate";

const navLinks = [
  {
    path: "/home",
    name: "Home",
  },
  {
    path: "/about",
    name: "About",
  },
  {
    path: "/blogs",
    name: "Blog",
  },
  {
    path: "/contact",
    name: "Contact",
  },
  {
    path: "/carlist",
    name: "All Vehicles",
  },
];

const Header = () => {
  const menuRef = useRef(null);
  const [categoryItem, setCategoryItem] = useState(navLinks);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  useEffect(() => {
    let isMounted = true; // Note this flag denotes mount status

    axios.get('https://admin.rentacarsrilankahbs.com/api/all/category/subcategory')
      .then(res => {
        if (isMounted) {
          setCategoryItem(prevItems => [...prevItems, ...res.data]);
          console.log(res);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the cars!", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const closeMenuOnClick = () => {
    if (window.innerWidth <= 768) { // Close only on mobile view
      menuRef.current.classList.remove("menu__active");
    }
  };

  return (
    <header className="header">
      {/* ============ header top ============ */}
      <div className="header__top">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="6">
              <div className="header__top__left fw-600">
                <span>Need Help? </span>
                <span className="header__top__help">
                  <i className="ri-phone-fill"></i> <a href ="tel:+94777425008" className="text-white">+94 777425008</a> / <a href ="tel:++94777425008" className="text-white">+94 777425008</a>
                </span>
              </div>
            </Col>

            <Col lg="6" md="6" sm="6">
              <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                <Link to="#" className="fw-600 d-flex align-items-center gap-1">
                  Dambulla
                </Link>

                <button className="header__btn btn ">
                  <Link to="tel:+94777425008">
                    <i className="ri-phone-line"></i> Request a call
                  </Link>
                </button>
              </div>
            </Col>

          </Row>
        </Container>
      </div>

      {/* =============== header middle =========== */}
      <div className="header__middle">
        <Container>
          <Row>
            <Col lg="3" md="3" sm="4">
              <div className="logo">
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <img src={Logo} alt="" className="" />
                </Link>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i className="ri-roadster-fill"></i>
                </span>
                <div className="header__location-content">
                  <h4>More Than 50 Vehicles</h4>
                  <h6>24 Hours a Day</h6>
                </div>
              </div>
            </Col>

            <Col lg="6" md="6" sm="4" style={{textAlign:"end"}}>
              <GoogleTranslate />
            </Col>

          </Row>
        </Container>
      </div>

      {/* ========== main navigation =========== */}
      <div className="main__navbar">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <i className="ri-menu-line" onClick={toggleMenu}></i>
            </span>

            <div className="navigation" ref={menuRef}>
              <div className="menu">
                {/* "Home" Item */}
                {categoryItem.map((item, index) => (
                  item.name === "Home" && (
                    <NavLink
                      to={item.path ? item.path : `/carlist/${item.id}`}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active nav__item fw-600" : "nav__item fw-600"
                      }
                      key={index}
                      onClick={closeMenuOnClick} // Close menu on item click
                    >
                      {item.name}
                    </NavLink>
                  )
                ))}

                {/* "Vehicle & Rents" Dropdown */}
                <div
                  className={`nav__item fw-600 dropdown ${isDropdownOpen ? 'open' : ''}`}
                  onMouseEnter={() => setIsDropdownOpen(true)}  // Open dropdown on hover
                  onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown on leave
                >
                  <span
                    className="nav__link dropdown-toggle"
                    id="vehicleRentDropdown"
                    role="button"
                  >
                    Vehicle & Rents
                  </span>
                  <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="vehicleRentDropdown">
                    {categoryItem.map((item, index) => (
                      item.name !== "Home" && item.name !== "About" && item.name !== "Blog" && item.name !== "Contact" && (
                        <NavLink
                          to={item.path ? item.path : `/carlist/${item.id}`}
                          className={(navClass) =>
                            navClass.isActive ? "nav__active dropdown-item" : "dropdown-item"
                          }
                          key={index}
                          onClick={closeMenuOnClick} // Close menu on item click
                        >
                          {item.name}
                        </NavLink>
                      )
                    ))}
                  </div>
                </div>

                {/* Other Navigation Items */}
                {categoryItem.map((item, index) => (
                  (item.name === "About" || item.name === "Blog" || item.name === "Contact") && (
                    <NavLink
                      to={item.path ? item.path : `/carlist/${item.id}`}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active nav__item fw-600" : "nav__item fw-600"
                      }
                      key={index}
                      onClick={closeMenuOnClick} // Close menu on item click
                    >
                      {item.name}
                    </NavLink>
                  )
                ))}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
