import React from "react";
import "../../styles/become-driver.css";
import { Container, Row, Col } from "reactstrap";

import driverImg from "../../assets/all-images/toyota-offer-2.png";

const BecomeDriverSection = () => {
  return (
    <section className="become__driver">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h2 className="section__title" style={{color:"#fff"}}>Our Mission</h2>
              <p className="section__description" style={{color:"#fff"}}>
                Our mission is to provide reliable and affordable transportation solutions that cater to the unique needs of each traveler. Whether you’re on a business trip, a family vacation, or simply need a vehicle for daily use, we have the perfect car for you. We believe that every journey should be hassle-free and enjoyable, and we work tirelessly to ensure that our customers receive the best possible service.
              </p>

              <h2 className="section__title" style={{color:"#fff"}}>Our Commitment</h2>
              <p className="section__description" style={{color:"#fff"}}>
              At HBS Rent A Car, we are more than just a rental company; we are your travel partner. Our team of dedicated professionals is always ready to go the extra mile to ensure your satisfaction. We understand that every journey is unique, and we strive to provide personalized solutions that exceed your expectations.

Thank you for choosing HBS Rent A Car. We look forward to being a part of your next adventure.
              </p>

            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={driverImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BecomeDriverSection;
