import React, { useEffect, useState } from "react";
import axios from 'axios';
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col, Input, Button } from "reactstrap";
import FindCarForm from "../components/UI/FindCarForm";
import ListViewItem from "../components/UI/ListViewItem";

const Home = () => {
  const [vehicleData, setVehicleData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    axios.get('https://admin.rentacarsrilankahbs.com/api/product/allProduts', {
      signal: controller.signal,
    })
      .then(res => {
        if (isMounted) {
          setVehicleData(res.data);
        }
      })
      .catch(error => {
        if (isMounted) {
          setError("There was an error fetching the cars!");
          console.error(error);
        }
      });

    return () => { 
      isMounted = false; 
      controller.abort();
    };
  }, []);

  // Filter vehicles based on search query
  const filteredVehicles = vehicleData.filter(vehicle =>
    vehicle.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Get current vehicles for the page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredVehicles.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(filteredVehicles.length / itemsPerPage);

  // Function to handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Helmet title="Home">
      <section className="list_item_view_bg">
        <Container>
          <Row>
            <Col lg="5" md="12" sm="12" className="P0">
              <FindCarForm />
            </Col>
            <Col lg="7" md="12" sm="12" className="P0 landing-vehicle-list">
              {/* Search input */}
              <Input 
                type="text" 
                placeholder="Search vehicles..." 
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="mb-4"
              />

              {error ? (
                <p>{error}</p>
              ) : (
                <>
                  {currentItems.map((item) => (
                    <ListViewItem item={item} key={item.id} />
                  ))}

                  {/* Pagination controls */}
                  <div className="pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                      <Button 
                        key={index + 1}
                        onClick={() => paginate(index + 1)} 
                        color={currentPage === index + 1 ? 'primary' : 'secondary'}
                        className="m-1"
                      >
                        {index + 1}
                      </Button>
                    ))}
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;
