import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CarUpload = ({ car, onSave }) => {
    const [name, setName] = useState(car ? car.name : '');
    const [description, setDescription] = useState(car ? car.description : '');
    const [image, setImage] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        if (image) formData.append('image', image);

        try {
            const url = car ? `http://127.0.0.1:8888/api/cars/${car.id}` : 'http://127.0.0.1:8888/api/cars';
            const method = car ? 'put' : 'post';
            const response = await axios[method](url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            toast.success("Car uploaded successfully");
            
            setName('');
            setDescription('');
            setImage(null);

            onSave(response.data);
        } catch (error) {
            toast.error("Error uploading car");
            console.error(`Error ${car ? 'updating' : 'uploading'} car`, error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <ToastContainer />
            <div>
                <label>Name:</label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
            </div>
            <div>
                <label>Description:</label>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
            </div>
            <div>
                <label>Image:</label>
                <input type="file" onChange={(e) => setImage(e.target.files[0])} />
            </div>
            <button type="submit">{car ? 'Update Car' : 'Upload Car'}</button>
        </form>
    );
}

export default CarUpload;
