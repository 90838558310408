import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "reactstrap";
import axios from 'axios';
import { Link } from "react-router-dom";
import "../../styles/list-view-item.css";

const CarItem = (props) => {
    const { id, image_path, model, name, discount_type, speed, current_sale_price, size, wholesale_minimum_qty, current_purchase_cost } = props.item;

    useEffect(() => {
        let isMounted = true; // Note this flag denotes mount status

        axios.get('https://admin.rentacarsrilankahbs.com/api/all/category/subcategory')
            .then(res => {
                if (isMounted) {
                    // setCars(res.data);
                    //   console.log(res);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the cars!", error);
            });

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <>
            <Link to={`/cars/${id}`} className="text-d-none">
                <Container className="list_view_car__item car__item mb-2">
                    <Row>
                        <Col xs="5" sm="5" md="3" lg="3" className="p-0 d-flex align-items-center">
                            <div className="car__img">
                                <img src={`https://admin.rentacarsrilankahbs.com/${image_path}`} alt="Image" className="w-100" />
                            </div>
                        </Col>

                        <Col xs="7" sm="7" md="9" lg="9">
                            <div className="car__item-content">
                                <Row>
                                    <Col lg="12" md="12">
                                        <h6 className="section__title text-start">{name}</h6>
                                    </Col>
                                    {/* <Col lg="4" md="4">
                                        <h6 className="rent__price text-end mt-">
                                            Rs. {(current_sale_price * 1).toFixed(2)} <span>/ Day</span>
                                        </h6>
                                    </Col> */}
                                </Row>



                                <div className="car__list__view_item-info car__item-info d-flex align-items-center justify-content-between mt-1 mb-1">
                                    <span className="d-flex flex-column align-items-center" style={{ marginBottom: '2px' }}>
                                        <i className="ri-settings-2-line" style={{ fontSize: '24px' }}></i> 
                                        {discount_type == 0 ? 'Manual' : 'Auto'}
                                    </span>
                                    
                                    <span className="d-flex flex-column align-items-center" style={{ marginBottom: '2px' }}>
                                        <i className="ri-money-dollar-box-fill" style={{ fontSize: '24px' }}></i> 
                                        Price List
                                    </span>
                                    
                                    <span className="d-flex flex-column align-items-center" style={{ marginBottom: '2px' }}>
                                        <i className="ri-gas-station-fill" style={{ fontSize: '24px' }}></i> 
                                        {size}
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Link>
        </>
    );
};

export default CarItem;
